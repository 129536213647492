import { useNavigate, useLocation, useRoutes } from 'react-router-dom';
import router from 'src/router';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { useAuth0 } from '@auth0/auth0-react';
import SuspenseLoader from './components/SuspenseLoader';
import React, { useEffect } from 'react';

function App() {

  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {

    if (!isAuthenticated && !isLoading) {

      loginWithRedirect({
        appState: { returnTo: window.location.pathname }
      }).then();

    } else if (isAuthenticated) {

      const intendedPath = location.state?.from || window.location.pathname;
      if (location.pathname !== intendedPath) {
        navigate(intendedPath);
      }
    }

  }, [isAuthenticated, isLoading]);

  if (isLoading) {
    return <SuspenseLoader />;
  }

  const content = useRoutes(router);

  return isAuthenticated && (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
