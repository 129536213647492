import React, { FC } from 'react';
import { Chip, Grid, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { MetricType } from './MetricType';

interface ChildProps {
  label: string;
  type?: MetricType;
  values: number[];
  assessments?: Assessment[];
  unit?: string;
}

enum Assessment {
  NEUTRAL, GOOD, BAD
}

const CompareMetricValue: FC<ChildProps> = ({
                                              label,
                                              type = null,
                                              values,
                                              assessments = Array(values.length).fill(Assessment.NEUTRAL),
                                              unit = ''
                                            }) => {

    let theme = useTheme();
    let colors = theme.colors;
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isLg = useMediaQuery(theme.breakpoints.only('lg'));

    return (
      //  Label Chip with Responsive Width
      <Grid container spacing={isXs ? 0.5 : 2} mb={0.5} wrap="nowrap">
        <Grid item xs={values.length == 3 ? 4.2 : 7} sm={4} lg={4} minWidth={isLg ? 330 : 0}>
          {/*<Tooltip key={label + '-compareValue-tooltip' + index} title="Year 2022: 3.3 vs. 2023: 4.7" placement="left" arrow>*/}
          <Chip
            label={label}
            variant="filled"
            sx={{
              width: '100%',  // Let the grid handle the responsive width
              justifyContent: 'flex-start',
              fontWeight: 'bold',
              pl: 0.5
            }}
          />
        </Grid>

        {/* Value Chips with Responsive Width */}
        {values.map((value, index) => (
          <Grid key={label + '-compareValue' + index} item
                xs={values.length == 3 ? 2.7 : 5}
                sm={values.length == 3 ? 1.5 : 3} lg={1.5}>
            <Tooltip
              arrow
              title={
                type == MetricType.FORWARD_PE ?
                  assessments[index] == Assessment.BAD ?
                    `The '` + label + `' is higher than the TTM value indicating analysts assume earnings are declining next year which is a bad sign.` :
                    `The '` + label + `' is lower than the TTM value indicating analysts assume earnings are improving next year which is a good sign.`
                  : type == MetricType.NEXT_YEAR_EXPECTED_EPS_GROWTH ?
                    assessments[index] == Assessment.BAD ?
                      `The '` + label + `' is lower than the TTM value indicating analysts assume earnings are declining next year which is a bad sign.` :
                      `The '` + label + `' is higher than the TTM value indicating analysts assume earnings are improving next year which is a good sign.`
                    : type == MetricType.NEXT_YEAR_EXP_REVENUE_GROWTH ?
                      assessments[index] == Assessment.BAD ?
                        `The '` + label + `' is lower than the TTM value indicating analysts assume revenues are declining next year which is a bad sign.` :
                        `The '` + label + `' is higher than the TTM value indicating analysts assume revenues are improving next year which is a good sign.`
                    : ''
              }
              disableHoverListener={assessments[index] === Assessment.NEUTRAL}
              enterTouchDelay={0}
              leaveTouchDelay={10000}>
              <Chip
                label={value !== -1 ? (value ? value.toFixed(isXs ? (value <= -100 || value >= 1000 ? 0 : 1) : 2) + unit : '') : 'n/a'}
                variant="filled"
                sx={{
                  width: '100%',  // Let the grid handle the responsive width
                  fontWeight: 'bold',
                  border: assessments[index] == Assessment.GOOD || assessments[index] == Assessment.BAD ? 5 : 0,
                  borderColor: assessments[index] == Assessment.GOOD || assessments[index] == Assessment.BAD ? (index === 0 ? colors.primary.light : index === 1 ? colors.info.light : colors.warning.light) : 'default',
                  bgcolor:
                    assessments[index] == Assessment.GOOD ? colors.success.light :
                      assessments[index] == Assessment.BAD ? colors.error.light :
                        index === 0 ? colors.primary.light : index === 1 ? colors.info.light : colors.warning.light
                }}
              />
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    );
  }
;

export default CompareMetricValue;