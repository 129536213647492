import { useAuth0 } from '@auth0/auth0-react';

export const useFetchWithAuth = () => {
  const { getAccessTokenSilently } = useAuth0();

  return async <T>(url: string, options: RequestInit = {}): Promise<T> => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json() as T;
    } catch (error) {
      console.error('Error with fetch request:', error);
      throw error;
    }
  };
};