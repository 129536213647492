function calculateChange(data: number[], value: number) {

  const indexOfValue = data.indexOf(value);
  const prevQuarter = data[indexOfValue - 1];
  const prevYear = data[indexOfValue - 4];
  const qoq = Math.round(((value - prevQuarter) / Math.abs(prevQuarter)) * 1000) / 10;
  const yoy = Math.round(((value - prevYear) / Math.abs(prevYear)) * 1000) / 10;

  return ' (QoQ: ' + (indexOfValue == 0 ? 'n/a' : qoq + '%') +
    ', YoY: ' + (indexOfValue < 3 ? 'n/a' : yoy + '%') + ')';
}

export const FinanceDataValueFormatter = (data: number[], value: number) => {
  return value + calculateChange(data, value);
};

export const FinanceDataMillionValueFormatter = (data: number[], value: number) => {
  return value + 'M' + calculateChange(data, value);
};

export const FinanceDataPercentageFormatter = (data: number[], value: number) => {
  return value + '%' + calculateChange(data, value);
};

export const FinanceDataMwhsFormatter = (data: number[], value: number) => {
  return value + ' MWhs' + calculateChange(data, value);
};