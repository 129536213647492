import { useContext } from 'react';
import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  styled,
  useTheme
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import HeaderButtons from './Buttons';
import HeaderUserBox from './Userbox';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const { hideSidebarToggle, toggleHideSidebar } = useContext(SidebarContext);
  const theme = useTheme();

  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
              lighten(theme.colors.primary.main, 0.7),
              0.15
            )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
              theme.colors.alpha.black[100],
              0.2
            )}, 0px 5px 22px -4px ${alpha(
              theme.colors.alpha.black[100],
              0.1
            )}`,
        [theme.breakpoints.up('lg')]: {
          width: `${!hideSidebarToggle ? 'auto' : '100%'}`,
          left: `${!hideSidebarToggle ? theme.sidebar.width : '0px'}`
        },
        [theme.breakpoints.down('lg')]: {
          width: '100%',
          left: '0px'
        }
      }}
    >
      <Box
        component="span"
        sx={{
          ml: 2,
          display: { lg: 'none', xs: 'inline-block' }
        }}
      >
        <IconButton color="primary" onClick={toggleSidebar}>
          {!sidebarToggle ? (
            <MenuTwoToneIcon fontSize="small" />
          ) : (
            <CloseTwoToneIcon fontSize="small" />
          )}
        </IconButton>
      </Box>
      <IconButton
        color="primary"
        onClick={toggleHideSidebar}
        sx={{
          display: { lg: 'inline-block', xs: 'none' }
        }}
      >
        {!hideSidebarToggle ? (
          <CloseTwoToneIcon fontSize="small" />
        ) : (
          <MenuTwoToneIcon fontSize="small" />
        )}
      </IconButton>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      />
      <Box display="flex" alignItems="center">
        <HeaderButtons />
        <HeaderUserBox />
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
