import { useState, createContext } from 'react';
import * as React from "react";

type SidebarContext = {

  sidebarToggle: any;
  toggleSidebar: () => void;
  closeSidebar: () => void;

  hideSidebarToggle: any;
  toggleHideSidebar: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

type SidebarProviderProps = {
  children?: React.ReactNode;
}

export const SidebarProvider = (props: SidebarProviderProps) => {

  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [hideSidebarToggle, setHideSidebarToggle] = useState(getInitialHideSidebarState);

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };
  const toggleHideSidebar = () => {
    setHideSidebarToggle(!hideSidebarToggle);
    localStorage.setItem('hideSidebarToggle', '' + !hideSidebarToggle)
  };

  function getInitialHideSidebarState() {
    return localStorage.getItem('hideSidebarToggle') == 'true';
  }

  return (
    <SidebarContext.Provider value={{
      sidebarToggle,
      toggleSidebar,
      closeSidebar,
      hideSidebarToggle,
      toggleHideSidebar,
    }}>
      {props.children}
    </SidebarContext.Provider>
  );
};
