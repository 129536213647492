import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Card, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Footer from 'src/components/Footer';
import PageTitleWrapper from '../../../components/PageTitleWrapper';
import CompareMetricValue from '../CompareMetricValue';
import { MetricType } from '../MetricType';
import { Assessment } from '../Assessment';
import SuspenseLoader from '../../../components/SuspenseLoader';
import CompareAutocomplete from './CompareAutocomplete';
import { useFetchWithAuth } from '../../../auth0/useFetchWithAuth';
import { Metrics } from '../metrics';

function updateTickerMetrics(company: any, setMetrics: (value: (((prevState: Metrics) => Metrics) | Metrics)) => void,
                             fetchWithAuth: <T>(url: string, options?: RequestInit) => Promise<T>) {
  if (company?.tickerSymbol?.length > 0) {
    const fetchData = async () => {
      try {
        const response = await fetchWithAuth<Metrics>('/api/metrics/search?ticker=' + company.tickerSymbol);
        setMetrics(response);
      } catch (error) {
        setMetrics(undefined);
      }
    };
    fetchData().then();
  }
}

function getCompanyFromLocalStorage(key: string) {
  return () => localStorage.getItem(key) != null ? JSON.parse(localStorage.getItem(key)) : null;
}

function MetricsCompare() {

  const [companies, setCompanies] = useState([]);
  const fetchWithAuth = useFetchWithAuth();

  // noinspection DuplicatedCode
  useEffect(() => {
    const fetchData = async () => {
      const response: any = await fetchWithAuth('/api/companies');
      setCompanies(response);
    };
    fetchData().then();
  }, []);

  if (!companies) {
    return (<SuspenseLoader />);
  }

  const [compareCompany1, setCompareCompany1] = useState(getCompanyFromLocalStorage('compareCompany1'));
  const [compareCompany2, setCompareCompany2] = useState(getCompanyFromLocalStorage('compareCompany2'));
  const [compareCompany3, setCompareCompany3] = useState(getCompanyFromLocalStorage('compareCompany3'));

  const [metrics1, setMetrics1] = useState<Metrics | undefined>(undefined);
  const [metrics2, setMetrics2] = useState<Metrics | undefined>(undefined);
  const [metrics3, setMetrics3] = useState<Metrics | undefined>(undefined);

  useEffect(() => updateTickerMetrics(compareCompany1, setMetrics1, fetchWithAuth), [compareCompany1]);
  useEffect(() => updateTickerMetrics(compareCompany2, setMetrics2, fetchWithAuth), [compareCompany2]);
  useEffect(() => updateTickerMetrics(compareCompany3, setMetrics3, fetchWithAuth), [compareCompany3]);

  const theme = useTheme();

  // Determine which breakpoint is currently active
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (isXs) {
      console.log('Current breakpoint: xs');
    } else if (isSm) {
      console.log('Current breakpoint: sm');
    } else if (isMd) {
      console.log('Current breakpoint: md');
    } else if (isLg) {
      console.log('Current breakpoint: lg');
    } else if (isXl) {
      console.log('Current breakpoint: xl');
    }
  }, [isXs, isSm, isMd, isLg, isXl]);

  return (
    <>
      <Helmet>
        <title>Metrics Comparison</title>
      </Helmet>

      <PageTitleWrapper>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              Compare Metrics
            </Typography>
          </Grid>
        </Grid>
      </PageTitleWrapper>

      <Container maxWidth="xl" sx={{ pl: isXs ? 0.2 : 2, pr: isXs ? 0.2 : 2 }}>
        <Card>
          <Container sx={{ ml: 0, mt: 0, mb: 0 }}>
            <Grid container mt={-0.75} spacing={2} wrap="nowrap">
              <Grid item xs={4} sm={4} lg={4} minWidth={isLg ? 330 : 0}></Grid>
              <Grid item xs={2.7} sm={1.5} lg={1.5}>
                <CompareAutocomplete companies={companies} selectedCompany={compareCompany1}
                                     onChange={(value) => {
                                       setCompareCompany1(value);
                                       localStorage.setItem('compareCompany1', JSON.stringify(value));
                                     }} />
              </Grid>

              <Grid item xs={2.7} sm={1.5} lg={1.5}>
                <CompareAutocomplete companies={companies} selectedCompany={compareCompany2}
                                     onChange={(value) => {
                                       setCompareCompany2(value);
                                       localStorage.setItem('compareCompany2', JSON.stringify(value));
                                     }} />
              </Grid>

              <Grid item xs={2.7} sm={1.5} lg={1.5}>
                <CompareAutocomplete companies={companies} selectedCompany={compareCompany3}
                                     onChange={(value) => {
                                       setCompareCompany3(value);
                                       localStorage.setItem('compareCompany3', JSON.stringify(value));
                                     }} />
              </Grid>
            </Grid>
          </Container>

          <Container sx={{ ml: 0, mt: 0, mb: 0 }}>

            <Grid container mt={-0.75} spacing={2} wrap="nowrap">
              <Grid item xs={4} sm={4} lg={4} minWidth={isLg ? 330 : 0}></Grid>
              <Grid item xs={2.7}
                    sm={1.5} lg={1.5}
                    justifyContent="space-between"
                    display={'flex'}>
                <Box fontSize={12} textAlign={'center'} ml={isUpMd ? 2 : 0}>{isXs ? 'P:' : 'Price:'}</Box>
                <Box fontSize={12} textAlign={'center'} mr={isUpMd ? 2 : 0}>${metrics1?.stockPrice}</Box>
              </Grid>
              <Grid item xs={2.7}
                    sm={1.5} lg={1.5}
                    justifyContent="space-between"
                    display={'flex'}>
                <Box fontSize={12} textAlign={'center'} ml={isUpMd ? 2 : 0}>{isXs ? 'P:' : 'Price:'}</Box>
                <Box fontSize={12} textAlign={'center'} mr={isUpMd ? 2 : 0}>${metrics2?.stockPrice}</Box>
              </Grid>
              <Grid item xs={2.7}
                    sm={1.5} lg={1.5}
                    justifyContent="space-between"
                    display={'flex'}>
                <Box fontSize={12} textAlign={'center'} ml={isUpMd ? 2 : 0}>{isXs ? 'P:' : 'Price:'}</Box>
                <Box fontSize={12} textAlign={'center'} mr={isUpMd ? 2 : 0}>${metrics3?.stockPrice}</Box>
              </Grid>
            </Grid>

            <Grid container mt={-2} spacing={2} wrap="nowrap">
              <Grid item xs={4} sm={4} lg={4} minWidth={isLg ? 330 : 0}></Grid>
              <Grid item xs={2.7}
                    sm={1.5} lg={1.5}
                    justifyContent="space-between"
                    display={'flex'}>
                <Box fontSize={12} textAlign={'center'} ml={isUpMd ? 2 : 0}>{isXs ? 'M:' : 'MCap:'}</Box>
                <Box fontSize={12} textAlign={'center'} mr={isUpMd ? 2 : 0}>$
                  {metrics1?.marketCap !== undefined && metrics1?.marketCap !== null ? (
                    metrics1.marketCap > 1000000 ? (metrics1.marketCap / 1000000).toFixed(2) + 'T' :
                      metrics1.marketCap > 1000
                        ? (metrics1.marketCap / 1000).toFixed(0) + 'B'
                        : metrics1.marketCap.toFixed(0) + 'M') : ('-')}
                </Box>
              </Grid>
              <Grid item xs={2.7}
                    sm={1.5} lg={1.5}
                    justifyContent="space-between"
                    display={'flex'}>
                <Box fontSize={12} textAlign={'center'} ml={isUpMd ? 2 : 0}>{isXs ? 'M:' : 'MCap:'}</Box>
                <Box fontSize={12} textAlign={'center'} mr={isUpMd ? 2 : 0}>$
                  {metrics2?.marketCap !== undefined && metrics2?.marketCap !== null ? (
                    metrics2.marketCap > 1000000 ? (metrics2.marketCap / 1000000).toFixed(2) + 'T' :
                      metrics2.marketCap > 1000
                        ? (metrics2.marketCap / 1000).toFixed(0) + 'B'
                        : metrics2.marketCap.toFixed(0) + 'M') : ('-')}
                </Box>
              </Grid>
              <Grid item xs={2.7}
                    sm={1.5} lg={1.5}
                    justifyContent="space-between"
                    display={'flex'}>
                <Box fontSize={12} textAlign={'center'} ml={isUpMd ? 2 : 0}>{isXs ? 'M:' : 'MCap:'}</Box>
                <Box fontSize={12} textAlign={'center'} mr={isUpMd ? 2 : 0}>$
                  {metrics3?.marketCap !== undefined && metrics3?.marketCap !== null ? (
                    metrics3.marketCap > 1000000 ? (metrics3.marketCap / 1000000).toFixed(2) + 'T' :
                      metrics3.marketCap > 1000
                        ? (metrics3.marketCap / 1000).toFixed(0) + 'B'
                        : metrics3.marketCap.toFixed(0) + 'M') : ('-')}
                </Box>
              </Grid>
            </Grid>

          </Container>

          <Container sx={{ ml: 0, mt: 2, mb: 0, pl: isXs ? 0.5 : 2, pr: isXs ? 0.5 : 2 }}>
            <Typography align={'center'} variant={'h5'} mb={1} maxWidth={isDownLg ? 180 : 310}>MANDATORY
              METRICS</Typography>
            <CompareMetricValue label={'TTM PE'} values={[metrics1?.ttmPe, metrics2?.ttmPe, metrics3?.ttmPe]} />
            <CompareMetricValue label={'FORWARD PE'}
                                type={MetricType.FORWARD_PE}
                                values={[metrics1?.forwardPe, metrics2?.forwardPe, metrics3?.forwardPe]}
                                assessments={[
                                  metrics1?.forwardPe < metrics1?.ttmPe ? Assessment.GOOD : Assessment.BAD,
                                  metrics2?.forwardPe < metrics2?.ttmPe ? Assessment.GOOD : Assessment.BAD,
                                  metrics3?.forwardPe < metrics3?.ttmPe ? Assessment.GOOD : Assessment.BAD
                                ]} />
            <CompareMetricValue label={isXs ? '2 YR FWD PE' : '2 YEAR FORWARD PE'} values={[-1, -1, -1]} />
          </Container>

          <Container sx={{ ml: 0, mt: 2, mb: 0, pl: isXs ? 0.5 : 2, pr: isXs ? 0.5 : 2 }}>
            <CompareMetricValue label={isXs ? 'TTM EPS GR' : 'TTM EPS GROWTH'}
                                values={[metrics1?.ttmEpsGrowth, metrics2?.ttmEpsGrowth, metrics3?.ttmEpsGrowth]}
                                unit={'%'} />
            <CompareMetricValue label={isXs ? 'C YR EPS GR' : 'CURRENT YR EXPECTED EPS GROWTH'}
                                values={[metrics1?.currentYearEpsGrowth, metrics2?.currentYearEpsGrowth, metrics3?.currentYearEpsGrowth]}
                                unit={'%'} />
            <CompareMetricValue label={isXs ? 'N YR EPS GR' : 'NEXT YR EXPECTED EPS GROWTH'}
                                type={MetricType.NEXT_YEAR_EXPECTED_EPS_GROWTH}
                                values={[metrics1?.nextYearEpsGrowth, metrics2?.nextYearEpsGrowth, metrics3?.nextYearEpsGrowth]}
                                assessments={[
                                  metrics1?.nextYearEpsGrowth > metrics1?.ttmEpsGrowth ? Assessment.GOOD : Assessment.BAD,
                                  metrics2?.nextYearEpsGrowth > metrics2?.ttmEpsGrowth ? Assessment.GOOD : Assessment.BAD,
                                  metrics3?.nextYearEpsGrowth > metrics3?.ttmEpsGrowth ? Assessment.GOOD : Assessment.BAD
                                ]}
                                unit={'%'} />
          </Container>

          <Container sx={{ ml: 0, mt: 2, mb: 0, pl: isXs ? 0.5 : 2, pr: isXs ? 0.5 : 2 }}>
            <CompareMetricValue label={isXs ? 'TTM REV GR' : 'TTM REV GROWTH'}
                                values={[metrics1?.ttmRevenueGrowth, metrics2?.ttmRevenueGrowth, metrics3?.ttmRevenueGrowth]}
                                unit={'%'} />
            <CompareMetricValue label={isXs ? 'C YR REV GR' : 'CURRENT YR EXPECTED REV GROWTH'}
                                values={[metrics1?.currentYearExpRevenueGrowth, metrics2?.currentYearExpRevenueGrowth, metrics3?.currentYearExpRevenueGrowth]}
                                unit={'%'} />
            <CompareMetricValue label={isXs ? 'N YR REV GR' : 'NEXT YR EXPECTED REV GROWTH'}
                                type={MetricType.NEXT_YEAR_EXP_REVENUE_GROWTH}
                                values={[metrics1?.nextYearExpRevenueGrowth, metrics2?.nextYearExpRevenueGrowth, metrics3?.nextYearExpRevenueGrowth]}
                                assessments={[
                                  metrics1?.nextYearExpRevenueGrowth > metrics1?.ttmRevenueGrowth ? Assessment.GOOD : Assessment.BAD,
                                  metrics2?.nextYearExpRevenueGrowth > metrics2?.ttmRevenueGrowth ? Assessment.GOOD : Assessment.BAD,
                                  metrics3?.nextYearExpRevenueGrowth > metrics3?.ttmRevenueGrowth ? Assessment.GOOD : Assessment.BAD
                                ]}
                                unit={'%'} />
          </Container>

          <Container sx={{ ml: 0, mt: 2, mb: 0, pl: isXs ? 0.5 : 2, pr: isXs ? 0.5 : 2 }}>
            <CompareMetricValue label={isXs ? 'G MRGN TTM' : 'GROSS MARGIN TTM'}
                                values={[metrics1?.grossMarginTtm, metrics2?.grossMarginTtm, metrics3?.grossMarginTtm]}
                                unit={'%'} />
            <CompareMetricValue label={isXs ? 'N MRGN TTM' : 'NET MARGIN TTM'}
                                values={[metrics1?.netMarginTtm, metrics2?.netMarginTtm, metrics3?.netMarginTtm]}
                                unit={'%'} />
            <CompareMetricValue label={isXs ? 'TTM P/S' : 'TTM P/S RATIO'}
                                values={[metrics1?.ttmPs, metrics2?.ttmPs, metrics3?.ttmPs]} />
            <CompareMetricValue label={isXs ? 'FWD P/S' : 'FORWARD P/S RATIO'}
                                values={[metrics1?.forwardPs, metrics2?.forwardPs, metrics3?.forwardPs]} />
          </Container>

          <Container sx={{ ml: 0, mt: 2, mb: 0, pl: isXs ? 0.5 : 2, pr: isXs ? 0.5 : 2 }}>
            <Typography align={'center'} variant={'h5'} marginBottom={1} maxWidth={isDownLg ? 180 : 310}>ADVANCED
              METRICS</Typography>
            <CompareMetricValue label={isXs ? 'L YR EPS GR' : 'LAST YR EPS GROWTH'}
                                values={[metrics1?.lastYearEpsGrowth, metrics2?.lastYearEpsGrowth, metrics3?.lastYearEpsGrowth]}
                                unit={'%'} />
            <CompareMetricValue label={isXs ? 'L YR REV GR' : 'LAST YR REV GROWTH'}
                                values={[metrics1?.lastYearRevenueGrowth, metrics2?.lastYearRevenueGrowth, metrics3?.lastYearRevenueGrowth]}
                                unit={'%'} />
          </Container>

          <Container sx={{ ml: 0, mt: 2, mb: 0, pl: isXs ? 0.5 : 2, pr: isXs ? 0.5 : 2 }}>
            <CompareMetricValue label={'PEG RATIO'}
                                values={[metrics1?.peg, metrics2?.peg, metrics3?.peg]} />
            <CompareMetricValue label={isXs ? 'ROE' : 'RETURN ON EQUITY'}
                                values={[metrics1?.roe, metrics2?.roe, metrics3?.roe]} unit={'%'} />
            <CompareMetricValue label={isXs ? 'ROIC' : 'RETURN ON INVESTED CAPITAL'}
                                values={[metrics1?.roic, metrics2?.roic, metrics3?.roic]} unit={'%'} />
            <CompareMetricValue label={isXs ? 'P/B' : 'PRICE TO BOOK'}
                                values={[metrics1?.priceToBook, metrics2?.priceToBook, metrics3?.priceToBook]} />
            <CompareMetricValue label={isXs ? 'P/FCF' : 'PRICE TO FREE CASH FLOW'}
                                values={[metrics1?.priceToFcf, metrics2?.priceToFcf, metrics3?.priceToFcf]} />
            <CompareMetricValue label={isXs ? 'FCF YIELD' : 'FREE CASH FLOW YIELD'}
                                values={[metrics1?.fcfYield, metrics2?.fcfYield, metrics3?.fcfYield]} unit={'%'} />
          </Container>

          <Container sx={{ ml: 0, mt: 2, mb: 0, pl: isXs ? 0.5 : 2, pr: isXs ? 0.5 : 2 }}>
            <CompareMetricValue label={isXs ? 'DIV YIELD' : 'DIVIDEND YIELD'}
                                values={[metrics1?.dividendYield, metrics2?.dividendYield, metrics3?.dividendYield]}
                                unit={'%'} />
            <CompareMetricValue label={isXs ? 'PYT RATIO' : 'PAYOUT RATIO'}
                                values={[metrics1?.payoutRatio, metrics2?.payoutRatio, metrics3?.payoutRatio]}
                                unit={'%'} />
          </Container>

          <Container sx={{ ml: 0, mt: 2, mb: 0, pl: isXs ? 0.5 : 2, pr: isXs ? 0.5 : 2 }}>
            <Typography pl={1} fontSize={11}>Numbers as
              of {new Date(metrics1?.extractedAt).toLocaleString('de-CH', {
                dateStyle: 'medium',
                timeStyle: 'short'
              })}</Typography>
          </Container>

          <Grid container height={10} />

        </Card>
      </Container>

      <Footer />
    </>
  );
}

export default MetricsCompare;
